import { z } from 'zod';
import {
  QueryLocationSchema,
  ResponseLocationSchema,
  SourceTrailSchema,
  YearSchema,
} from '../types';

// Main ref: https://www.climatiq.io/docs/api-reference/travel

/*
 * Types
 */

export const TravelModeSchema = z.enum(['air', 'car', 'rail']);
export type TravelMode = z.infer<typeof TravelModeSchema>;

/*
 * API Definition
 */

export const TravelRequestSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/travel#distance-based-method
  travel_mode: TravelModeSchema,
  origin: QueryLocationSchema,
  destination: QueryLocationSchema,
  distance_km: z.number().positive().optional(), // can be used instead of destination
  year: YearSchema,
});
export type TravelRequest = z.infer<typeof TravelRequestSchema>;

export const TravelResponseSchema = z.object({
  co2e: z.number().positive(),
  co2e_unit: z.enum(['kg']),
  distance_km: z.number().positive(),
  origin: ResponseLocationSchema,
  destination: ResponseLocationSchema,
  direct_emissions: z.object({
    source_trail: SourceTrailSchema,
  }),
  indirect_emissions: z.object({
    source_trail: SourceTrailSchema,
  }),
});
export type TravelResponse = z.infer<typeof TravelResponseSchema>;
