import z from 'zod';
import {
  EstimationSchema,
  SourceTrailSchema,
  UnitSchemas,
  YearSchema,
} from '../types';

// Main ref: https://www.climatiq.io/docs/api-reference/procurement

/*
 * Types
 */

export enum ActivityId {
  // Whitelist of known activity IDs we leverage in Niklas
  // Use the Data Explorer ( https://www.climatiq.io/data ) to find activity IDs
  // /!\
  // PROCUREMENT SUPPORTS ONLY EXIOBASE ACTIVITIES
  // /!\
  ITServices = 'professional_services-type_computer_related_services',
  ITHardware = 'office_equipment-type_office_machinery_computers',
  ResearchDevelopment = 'professional_services-type_research_development_services',
  RealEstateServices = 'real_estate-type_real_estate_services',
  HotelAndRestaurants = 'restaurants_accommodation-type_hotel_restaurant_services',
  EducationServices = 'education-type_education_services',
  PostTelecommunication = 'communication_services-type_post_telecommunication',
  ConstructionWork = 'construction-type_construction_work',
  ElectricalEquipment = 'electrical_equipment-type_electrical_machinery_apparatus_not_elsewhere_specified',
  InsuranceAndPension = 'insurance-type_insurance_pension_funding_services_except_compulsory_social_security_services',
  PaperAndPrint = 'paper_products-type_printed_matter_recorded_media',
  MarketingAndOtherBusinessServices = 'professional_services-type_other_business_services',
  Beverages = 'consumer_goods-type_beverages',
  FoodProducts = 'consumer_goods-type_food_products_not_elsewhere_specified',
  FurnitureOtherGoods = 'consumer_goods-type_furniture_other_manufactured_goods_not_elsewhere_specified',
  FinancialServices = 'financial_services-type_financial_intermediation_services_except_insurance_pension_funding_services',
  MachineryEquipment = 'machinery-type_machinery_equipment_not_elsewhere_specified',
  PaperProducts = 'paper_products-type_paper_paper_products',
  PassengerVehicles = 'passenger_vehicle-vehicle_type_motor_vehicles_trailers_semitrailers-fuel_source_na-engine_size_na-vehicle_age_na-vehicle_weight_na',
  TransportSupportServices = 'transport_services-type_supporting_auxiliary_and_travel_agency_services',
  VehicleSaleMaintenanceRepair = 'vehicle_manufacture-type_sale_maintenance_repair_parts_accessories_motor_vehicles_motorcycles',
  AuxiliaryFinancialServices = 'financial_services-type_services_auxiliary_to_financial_intermediation',
  WholesaleTradeServices = 'wholesale_trade-type_wholesale_trade_commission_trade_services_except_of_motor_vehicles_motorcycles',
  RecreationalCulturalSportingServices = 'consumer_goods-type_recreational_cultural_sporting_services',
  EquipmentRental = 'equipment_rental-type_rental_of_machinery_equipment_without_operator_of_personal_household_goods',
  CleaningServices = 'organizational_activity-type_other_services_not_elsewhere_specified', // fix 2024-11-20: EXIOBASE doesn't have anything specific for cleaning services, sadly
  OtherServices = 'organizational_activity-type_other_services_not_elsewhere_specified',
}
export const ActivityIdSchema = z.nativeEnum(ActivityId);

export type ActivityKey = keyof typeof ActivityId;
export const ActivityKeySchema = z.enum(
  Object.keys(ActivityId) as [ActivityKey, ...ActivityKey[]],
);

// This is important to put activities in the correct GHG Protocol scope category
export enum ActivityKind {
  PurchasedServices = 'services',
  PurchasedGoods = 'goods',
}
export const ActivityKindByKey: Record<ActivityKey, ActivityKind> = {
  ITServices: ActivityKind.PurchasedServices,
  ITHardware: ActivityKind.PurchasedGoods,
  ResearchDevelopment: ActivityKind.PurchasedServices,
  RealEstateServices: ActivityKind.PurchasedServices,
  HotelAndRestaurants: ActivityKind.PurchasedServices,
  EducationServices: ActivityKind.PurchasedServices,
  PostTelecommunication: ActivityKind.PurchasedServices,
  ConstructionWork: ActivityKind.PurchasedServices,
  ElectricalEquipment: ActivityKind.PurchasedGoods,
  InsuranceAndPension: ActivityKind.PurchasedServices,
  PaperAndPrint: ActivityKind.PurchasedGoods,
  MarketingAndOtherBusinessServices: ActivityKind.PurchasedServices,
  Beverages: ActivityKind.PurchasedGoods,
  FoodProducts: ActivityKind.PurchasedGoods,
  FurnitureOtherGoods: ActivityKind.PurchasedGoods,
  FinancialServices: ActivityKind.PurchasedServices,
  MachineryEquipment: ActivityKind.PurchasedGoods,
  PaperProducts: ActivityKind.PurchasedGoods,
  PassengerVehicles: ActivityKind.PurchasedGoods,
  TransportSupportServices: ActivityKind.PurchasedServices,
  VehicleSaleMaintenanceRepair: ActivityKind.PurchasedServices,
  AuxiliaryFinancialServices: ActivityKind.PurchasedServices,
  WholesaleTradeServices: ActivityKind.PurchasedServices,
  RecreationalCulturalSportingServices: ActivityKind.PurchasedServices,
  EquipmentRental: ActivityKind.PurchasedServices,
  CleaningServices: ActivityKind.PurchasedServices,
  OtherServices: ActivityKind.PurchasedServices,
};

/*
 * API Definition
 */

export const ProcurementRequestSchema = z
  .object({
    // Source: https://www.climatiq.io/docs/api-reference/procurement#request
    activity: z.object({
      activity_id: ActivityIdSchema,
    }),
    spend_year: YearSchema,
    spend_region: z.string(),
  })
  .merge(UnitSchemas.MoneySchema);
export type ProcurementRequest = z.infer<typeof ProcurementRequestSchema>;

export const ProcurementResponseSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/procurement#response
  estimate: EstimationSchema,
  calculation_details: z
    .object({
      tax_margin: z.number().nullable(),
      trade_margin: z.number(),
      transport_margin: z.number(),
      inflation_applied: z.number(),
    })
    .nullable(),
  source_trail: SourceTrailSchema,
});
export type ProcurementResponse = z.infer<typeof ProcurementResponseSchema>;
