import { z } from 'zod';
import { CountryCodeSchema, UnitSchemas, YearSchema } from '../types';
import { EnergyReportingQuadSchema } from './energy.types';

// Main ref: https://www.climatiq.io/docs/api-reference/energy#electricity-use

/*
 * Types
 */

// Regions used for detailed grid emissions
export const CountryWithRegionCodeSchema = z.enum([
  // Source: https://www.climatiq.io/docs/api-reference/energy#response-1
  // Note: we could support more power areas, we limit ourselves to states and common regions for now
  // Australia
  'AU-ACT',
  'AU-NSW',
  'AU-NT',
  'AU-QLD',
  'AU-SA',
  'AU-TAS',
  'AU-VIC',
  'AU-WA',
  // Canada
  'CA-AB',
  'CA-BC',
  'CA-MB',
  'CA-NB',
  'CA-NL',
  'CA-NS',
  'CA-NT',
  'CA-NU',
  'CA-ON',
  'CA-QC',
  'CA-SK',
  'CA-YT',
  // USA
  'US-AK',
  'US-AL',
  'US-AR',
  'US-AZ',
  'US-CA',
  'US-CO',
  'US-CT',
  'US-DC',
  'US-DE',
  'US-FL',
  'US-GA',
  'US-HI',
  'US-IA',
  'US-ID',
  'US-IL',
  'US-IN',
  'US-KS',
  'US-KY',
  'US-LA',
  'US-MA',
  'US-MD',
  'US-ME',
  'US-MI',
  'US-MN',
  'US-MO',
  'US-MS',
  'US-MT',
  'US-NC',
  'US-ND',
  'US-NE',
  'US-NH',
  'US-NJ',
  'US-NM',
  'US-NV',
  'US-NY',
  'US-OH',
  'US-OK',
  'US-OR',
  'US-PA',
  'US-PR',
  'US-RI',
  'US-SC',
  'US-SD',
  'US-TN',
  'US-TX',
  'US-UT',
  'US-VA',
  'US-VT',
  'US-WA',
  'US-WI',
  'US-WV',
  'US-WY',
]);
export type CountryWithRegionCode = z.infer<typeof CountryWithRegionCodeSchema>;

/*
 * API Definition
 */

export const ElectricityUseRequestSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/energy#electricity-use
  region: CountryCodeSchema.or(CountryWithRegionCodeSchema),
  year: YearSchema,
  recs: UnitSchemas.EnergySchema.optional(), // Renewable Energy Certificates
  components: z.array(
    z.object({
      amount: UnitSchemas.EnergySchema,
      energy_source: z.enum(['renewable']).optional(), // we only use to indicate renewable energy share
    }),
  ),
});
export type ElectricityUseRequest = z.infer<typeof ElectricityUseRequestSchema>;

export const ElectricityUseResponseSchema = z.object({
  location: EnergyReportingQuadSchema,
  market: EnergyReportingQuadSchema,
});
export type ElectricityUseResponse = z.infer<
  typeof ElectricityUseResponseSchema
>;
