import { z } from 'zod';
import {
  QueryLocationSchema,
  ResponseLocationSchema,
  SourceTrailSchema,
  UnitSchemas,
} from '../types';

// Main ref: https://www.climatiq.io/docs/api-reference/intermodal-freight

/*
 * Types
 */

export const TransportModeSchema = z.enum(['road', 'rail', 'sea', 'air']);
export type TransportMode = z.infer<typeof TransportModeSchema>;

/*
 * API Definition
 */

export const FreightRequestSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/intermodal-freight#request
  cargo: UnitSchemas.WeightSchema,
  route: z.array(
    z.union([
      z.object({
        location: QueryLocationSchema,
      }),
      z.object({
        transport_mode: TransportModeSchema,
      }),
    ]),
  ),
});
export type FreightRequest = z.infer<typeof FreightRequestSchema>;

export const FreightResponseSchema = z.object({
  co2e: z.number().positive(),
  co2e_unit: z.enum(['kg']),
  hub_equipment_co2e: z.number().gte(0),
  vehicle_operation_co2e: z.number().gte(0),
  vehicle_energy_provision_co2e: z.number().gte(0),
  distance_km: z.number().positive(),
  cargo_tonnes: z.number().positive(),
  route: z.array(
    z.union([
      z
        .object({
          type: z.enum(['location']),
          co2e: z.number().positive(),
          co2e_unit: z.enum(['kg']),
        })
        .merge(ResponseLocationSchema),
      z.object({
        type: z.enum(['leg']),
        co2e: z.number().positive(),
        co2e_unit: z.enum(['kg']),
        vehicle_operation_co2e: z.number().gte(0),
        vehicle_energy_provision_co2e: z.number().gte(0),
        transport_mode: TransportModeSchema,
        distance_km: z.number().positive(),
        source_trail: SourceTrailSchema,
      }),
    ]),
  ),
});
export type FreightResponse = z.infer<typeof FreightResponseSchema>;
