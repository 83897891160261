import { z } from 'zod';
import {
  QueryLocationSchema,
  ResponseLocationSchema,
  SourceTrailSchema,
  YearSchema,
} from '../types';

// Main ref: https://www.climatiq.io/docs/api-reference/travel#hotels

/*
 * API Definition
 */

export const HotelStayRequestSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/travel#hotels
  hotel_nights: z.number().int().positive(),
  location: QueryLocationSchema,
  year: YearSchema,
});
export type HotelStayRequest = z.infer<typeof HotelStayRequestSchema>;

export const HotelStayResponseSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/travel#response-2
  co2e: z.number().positive(),
  co2e_unit: z.enum(['kg']),
  location: ResponseLocationSchema,
  source_trail: SourceTrailSchema,
});
export type HotelStayResponse = z.infer<typeof HotelStayResponseSchema>;
