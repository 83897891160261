import { countries } from 'countries-list';
import z from 'zod';

// Shared enums and types to work with Climatiq APIs

export const YearSchema = z.number().int().gt(1970).lt(2150);
export type Year = z.infer<typeof YearSchema>;

export const CountryCodeSchema = z.enum(
  Object.keys(countries) as [
    keyof typeof countries,
    ...(keyof typeof countries)[],
  ],
);
export type CountryCode = z.infer<typeof CountryCodeSchema>;

export const QueryLocationSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/travel#query-location
  query: z.string(),
  country: CountryCodeSchema.optional(),
});
export type QueryLocation = z.infer<typeof QueryLocationSchema>;

export const ResponseLocationSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/travel#response-location
  name: z.string(),
  confidence_score: z.number().gt(0).lte(1).nullable(),
});
export type ResponseLocation = z.infer<typeof ResponseLocationSchema>;

export const UnitSchemas = {
  // Source: https://www.climatiq.io/docs/api-reference/models/parameters#available-unit-types
  // Add more as needed!

  EnergySchema: z.object({
    energy: z.number().positive(),
    energy_unit: z.enum([
      'Wh',
      'kWh',
      'MWh',
      'MJ',
      'GJ',
      'TJ',
      'therm',
      'MMBTU',
    ]),
  }),

  MoneySchema: z.object({
    money: z.number().positive(),
    money_unit: z.enum([
      'afn',
      'dzd',
      'ars',
      'aud',
      'bhd',
      'brl',
      'cad',
      'kyd',
      'cny',
      'dkk',
      'egp',
      'eur',
      'hkd',
      'huf',
      'isk',
      'inr',
      'iqd',
      'ils',
      'jpy',
      'lbp',
      'mxn',
      'mad',
      'nzd',
      'nok',
      'qar',
      'rub',
      'sar',
      'sgd',
      'zar',
      'krw',
      'sek',
      'chf',
      'twd',
      'thb',
      'tnd',
      'try',
      'aed',
      'gbp',
      'usd',
    ]),
  }),

  WeightSchema: z.object({
    weight: z.number().positive(),
    weight_unit: z.enum([
      'g',
      'kg',
      't', // metric ton
      'lb',
      'ton', // US short ton
    ]),
  }),

  VolumeSchema: z.object({
    volume: z.number().positive(),
    volume_unit: z.enum(['ml', 'l', 'm3', 'gallons_us', 'bbl']),
  }),
};

export const UsedEmissionFactorSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/audit-trail#used-emission-factor
  id: z.string(),
  name: z.string(),
  activity_id: z.string(),
  access_type: z.enum(['private', 'public']),
  source: z.string(),
  source_dataset: z.string(),
  year: z.number(),
  region: z.string(),
  category: z.string(),
  source_lca_activity: z.string(),
  data_quality_flags: z.array(z.string()),
});
export type UsedEmissionFactor = z.infer<typeof UsedEmissionFactorSchema>;

export const ConstituentGasesSchema = z.object({
  // source: https://www.climatiq.io/docs/api-reference/audit-trail#constituent-gases
  co2e_total: z.number().nullable().optional(),
  co2e_other: z.number().nullable().optional(),
  co2: z.number().nullable(),
  ch4: z.number().nullable(),
  n2o: z.number().nullable(),
});
export type ConstituentGases = z.infer<typeof ConstituentGasesSchema>;

export const EstimationSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/models/estimation
  co2e: z.number(),
  co2e_unit: z.enum(['kg']), // possibly others, like tons? unclear in documentation
  co2e_calculation_method: z.enum(['ar4', 'ar5', 'ar6']),
  co2e_calculation_origin: z.enum(['source', 'climatiq']),
  emission_factor: UsedEmissionFactorSchema.nullable(),
  constituent_gases: ConstituentGasesSchema.nullable(),
  activity_data: z.object({
    activity_value: z.number(),
    activity_unit: z.string(),
  }),
  audit_trail: z.enum(['enabled', 'disabled']),
});
export type Estimation = z.infer<typeof EstimationSchema>;

export const SourceTrailSchema = z.array(
  z.object({
    name: z.string().nullable(),
    source: z.string().nullable(),
    source_dataset: z.string().nullable(),
    year: z.coerce.number().nullable(),
    region: z.string().nullable(),
    region_name: z.string().nullable(),
    data_category: z.string().nullable(),
  }),
);
export type SourceTrail = z.infer<typeof SourceTrailSchema>;
