import { z } from 'zod';
import { ConstituentGasesSchema, SourceTrailSchema } from '../types';

// Types shared by energy endpoints

export const EnergyEstimateSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/energy#energy-estimate
  co2e: z.number().gte(0),
  co2e_unit: z.enum(['kg']),
  source_trail: SourceTrailSchema,
  constituent_gases: ConstituentGasesSchema.nullable(),
});
export type EnergyEstimate = z.infer<typeof EnergyEstimateSchema>;

export const EnergyReportingQuadSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/energy#energy-reporting-quad
  consumption: EnergyEstimateSchema,
  transmission_and_distribution: EnergyEstimateSchema,
  well_to_tank: EnergyEstimateSchema,
  well_to_tank_of_transmission_and_distribution: EnergyEstimateSchema,
});
export type EnergyReportingQuad = z.infer<typeof EnergyReportingQuadSchema>;
