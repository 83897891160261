import { z } from 'zod';
import { CountryCodeSchema, UnitSchemas, YearSchema } from '../types';
import { EnergyEstimateSchema } from './energy.types';

// Main ref: https://www.climatiq.io/docs/api-reference/energy#fuel-use

/*
 * Types
 */

// Note: There are A LOT (200+) of supported fuel types in Climatiq.
// We only support a few here, and we'll likely add more as needed.
export enum FuelCombustionFuelType {
  Biomass = 'other_biomass_bio_100',
  Coal = 'coal',
  Diesel = 'diesel',
  Fuel = 'fuel_oil',
  Gasoline = 'motor_gasoline',
  LiquidPetroleumGas = 'lpg',
  NaturalGas = 'natural_gas',
  Wood = 'wood_bio_100',
}
export const FuelCombustionFuelTypeSchema = z.enum(
  Object.values(FuelCombustionFuelType) as [
    FuelCombustionFuelType,
    ...FuelCombustionFuelType[],
  ],
);

export type SupportedCombustionFuelUnitType = 'energy' | 'volume' | 'weight';

export const SupportedCombustionFuelUnitsByFuelType: Record<
  FuelCombustionFuelType,
  SupportedCombustionFuelUnitType[]
> = {
  [FuelCombustionFuelType.Biomass]: ['energy', 'volume'],
  [FuelCombustionFuelType.Coal]: ['energy', 'weight'],
  [FuelCombustionFuelType.Diesel]: ['energy', 'volume', 'weight'],
  [FuelCombustionFuelType.Fuel]: ['energy', 'volume', 'weight'],
  [FuelCombustionFuelType.Gasoline]: ['energy', 'volume', 'weight'],
  [FuelCombustionFuelType.LiquidPetroleumGas]: ['energy', 'volume', 'weight'],
  [FuelCombustionFuelType.NaturalGas]: ['energy', 'volume', 'weight'],
  [FuelCombustionFuelType.Wood]: ['energy', 'weight'],
};

/*
 * API Definition
 */

export const FuelCombustionRequestSchema = z.object({
  region: CountryCodeSchema,
  year: YearSchema,
  fuel_type: FuelCombustionFuelTypeSchema,
  amount: z.union([
    UnitSchemas.EnergySchema,
    UnitSchemas.VolumeSchema,
    UnitSchemas.WeightSchema,
  ]),
});
export type FuelCombustionRequest = z.infer<typeof FuelCombustionRequestSchema>;

export const FuelCombustionResponseSchema = z.object({
  combustion: EnergyEstimateSchema,
  well_to_tank: EnergyEstimateSchema,
});
export type FuelCombustionResponse = z.infer<
  typeof FuelCombustionResponseSchema
>;
